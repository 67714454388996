<template>
  <div
    class="row d-flex align-items-center justify-content-center flex-column h-100"
  >
    <form class="col-4" @submit.prevent="handleSubmit">
      <div class="row">
        <h1 class="col my-2 text-center">
          Sign in with SSO
        </h1>
      </div>

      <div class="row">
        <div class="col-12 input-group" :class="{'invalid': error}">
          <b-input
            type="text"
            v-model.trim="email"
            :state="emailState"
            v-focus
            data-testid="email"
            id="email"
            name="email"
            ref="email"
            aria-label="Email"
            placeholder="Email"
          />
          <Error class="medium-tan">
            <span>{{ error }}</span>
          </Error>
        </div>
      </div>
      <div class="row">
        <div class="col mt-5">
          <Button
            data-testid="loginButton"
            type="submit"
            class="primary lg"
            :is-loading="isLoading"
            :disabled="disableSubmitState"
          >
            Sign in
          </Button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <BackToLoginLink />
          <router-link to="/signup">
            Not a Balloon user? Sign up
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SAMLLoginView',

  props: {
    email: { type: String, default: '' },
  },

  data() {
    return {
      error: '',
      flightId: this.$route.query.flightId || '',
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters(['validateEmail']),
    emailState() {
      return this.email.length > 0 ? this.validateEmail(this.email) : null;
    },
    disableSubmitState() {
      return !this.emailState;
    },
  },

  watch: {
    $route() {
      this.$nextTick(() => this.$refs.email.focus && this.$refs.email.focus());
    },
  },

  methods: {
    handleSubmit() {
      return this.samlLogin();
    },

    async samlLogin() {
      try {
        this.isLoading = true;
        const data = { email: this.email };
        if (this.flightId) {
          data.flightId = this.flightId;
        }
        await this.$store.dispatch('samlLogin', data);
      } catch (err) {
        this.error = err.message;
        this.$store.dispatch('errorToast', 'Your team does not use SSO for log in.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
form {
  border-radius: 4px;
  padding: 30px 30px;
  flex-basis: auto;

  button {
    text-transform: capitalize;
  }
}

a {
  color: @medium-tan;
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}
</style>
