<template>
  <LoadingBalloonAnimation text="Accessing flight..." />
</template>

<script>
import { getFlightId } from '@/utils/userToken';

export default {
  name: 'FlightLinkLoginView',

  props: {
    token: { type: String, required: true },
  },

  async created() {
    await this.$store.dispatch('login', { token: this.token });
    const flightId = getFlightId();
    if (flightId) {
      this.$router.push({ name: 'flight-landing', params: { id: flightId } });
    } else {
      this.$router.push({ name: 'root' });
    }
  },
};
</script>
