<template>
  <div class="row d-flex align-items-center justify-content-center flex-column h-100">
    <form class="col-4" @submit.prevent="handleSubmit">
      <div class="row">
        <h1 class="col my-2 text-center">
          {{ getHeaderText() }}
        </h1>
      </div>

      <div class="row">
        <div class="col-12 input-group" :class="{'invalid': error }">
          <b-input type="text"
            v-model.trim="email"
            :state="emailState"
            v-focus
            data-testid="email"
            id="email"
            name="email"
            ref="email"
            aria-label="Email"
            placeholder="Email"
          />
          <Error class="medium-tan">
            <span>{{ error }}</span>
          </Error>
          <Tip color="medium-tan" :showTitle="false" class="mb-4">
            We will email you a magic link for a password-free sign in. Or you can
            <router-link
             :to="'/manual-login'"
             class="underline">sign in with a password instead.</router-link>
          </Tip>
        </div>
      </div>

      <div class="row">
        <div class="col mt-5">
          <Button
            data-testid="loginButton"
            type="submit"
            class="primary lg"
            :is-loading="isLoading"
            :disabled="disableSubmitState">
            {{ getSubmitButtonText() }}
          </Button>
        </div>
      </div>
      <div class="row">
        <div class="col mt-2">
          <p class="tan my-4 text-center">Or</p>
          <router-link
            data-testid="ssoLoginButton"
            to="/saml-login"
            tag="button"
            class="primary w-100"
            >
            Sign in with SSO
          </router-link>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <BackToLoginLink :show-manual="true" :show-email="false" />
          <router-link to="/signup">
            Not a Balloon user? Sign up
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PasswordlessLoginView',

  data() {
    return {
      error: '',
      email: this.$route.query.email || '',
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters([
      'validateEmail',
    ]),
    emailState() {
      return this.email.length > 0 ? this.validateEmail(this.email) : null;
    },
    disableSubmitState() {
      return !this.emailState;
    },
  },

  watch: {
    $route() {
      this.$nextTick(() => this.$refs.email.focus && this.$refs.email.focus());
    },
  },

  methods: {
    getHeaderText() {
      return 'Sign in to Balloon';
    },
    getSubmitButtonText() {
      return 'Sign in Password-Free';
    },
    handleSubmit() {
      return this.requestLoginLink();
    },
    async requestLoginLink() {
      try {
        this.isLoading = true;
        await this.$store.dispatch('loginLink', { email: this.email });
        this.email = '';
      } catch (err) {
        this.error = err.message;
        this.$store.dispatch('errorToast', 'There was a problem requesting login link.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
form {
  border-radius: 4px;
  padding: 30px 30px;
  flex-basis: auto;

  button {
    text-transform: capitalize;
  }
}

a {
  color: @medium-tan;

  &:hover { text-decoration: underline }
}

span a { font-size: 1rem;}
</style>
