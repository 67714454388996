<template>
<div class="row d-flex align-items-center justify-content-center flex-column h-100">
  <form class="col-5" @submit.prevent="handleSubmit">
    <div class="row">
      <h1 class="col text-center">
        {{ claimAccount ? 'Claim your account': 'Confirm your account details'}}
      </h1>
    </div>

    <div class="row">
      <div class="col input-group" :class="{'invalid': $v.firstName.$error }">
        <b-input
          type="text"
          v-focus
          v-model.trim="$v.firstName.$model"
          lazy-formatter
          id="first-name-input"
          ref="first-name-input"
          aria-label="First Name"
          placeholder="First Name"/>
        <Error class="white">
          <div>
            <span v-if="!$v.firstName.required">First name is a required field</span>
            <span v-if="!$v.firstName.minLength">
              Last name must be at least {{ $v.firstName.$params.minLength.min}} characters
            </span>
          </div>
        </Error>
      </div>
    </div>
    <div class="row">
      <div class="col input-group" :class="{'invalid': $v.lastName.$error }">
        <b-input
          type="text"
          v-model.trim="$v.lastName.$model"
          lazy-formatter
          id="last-name-input"
          ref="last-name-input"
          aria-label="Last Name"
          placeholder="Last Name"/>
        <Error class="white">
          <div>
            <span v-if="!$v.lastName.required">Last name is a required field</span>
            <span v-if="!$v.lastName.minLength">
              Last name must be at least {{ $v.lastName.$params.minLength.min}} characters
            </span>
          </div>
        </Error>
      </div>
    </div>
    <div class="row">
      <div class="col input-group" :class="{'invalid': $v.password.$error }">
        <b-input
          type="password"
          v-model.trim="$v.password.$model"
          lazy-formatter
          id="password-input"
          ref="password-input"
          aria-label="Password"
          placeholder="Password"/>
        <Error class="white">
          <div>
            <span v-if="!$v.password.required">Password is required</span>
            <span v-if="!$v.password.password">
              Your password is not secure enough, check the requirements below.
            </span>
          </div>
        </Error>
      </div>
    </div>
    <div class="row">
      <div class="col input-group" :class="{'invalid': $v.confirmedPassword.$error }">
        <b-input
          type="password"
          v-model.trim="$v.confirmedPassword.$model"
          lazy-formatter
          id="confirm-password-input"
          aria-label="Confirm Password"
          placeholder="Confirm Password"/>
          <Error class="white">
            <div>
              <span v-if="!$v.confirmedPassword.required">
                You need to confirm your password
              </span>
              <span v-if="!$v.confirmedPassword.sameAsPassword">
                Passwords don't match
              </span>
            </div>
          </Error>
      </div>
    </div>
    <PasswordTip />
    <div class="row">
      <div class="col mt-5">
        <Button
          type="submit"
          class="primary lg"
          :is-loading="isLoading"
          :disabled="$v.$invalid">
          {{ claimAccount ? 'Claim Account' : 'Set Account'}}
        </Button>
        <BackToLoginLink/>
      </div>
    </div>
  </form>
</div>
</template>

<script>
import { mapGetters } from 'vuex';

import { validationMixin } from 'vuelidate';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import { password } from '@/utils/custom-validators';
import { getTeamIdFromToken } from '@/utils/userToken';

import PasswordTip from '@/components/Password/PasswordTip.vue';

export default {
  name: 'ConfirmAccountView',
  mixins: [validationMixin],

  components: { PasswordTip },

  props: {
    token: { type: String, required: true },
    claimAccount: { type: Boolean, default: false },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      activeTeamId: '',
      password: '',
      confirmedPassword: '',
      isLoading: false,
    };
  },

  validations: {
    firstName: { required, minLength: minLength(2) },
    lastName: { required, minLength: minLength(2) },
    password: { required, password },
    confirmedPassword: { required, sameAsPassword: sameAs('password') },
  },

  async created() {
    this.checkToken();
  },

  computed: {
    ...mapGetters([
      'loggedInUser',
    ]),
  },

  methods: {
    async checkToken() {
      try {
        this.isLoading = true;
        this.activeTeamId = getTeamIdFromToken(this.token);
        if (!this.claimAccount) { await this.$store.dispatch('tokenCheckSignup', this.token); }
      } catch (err) {
        this.$store.dispatch('errorToast',
          'There was an error validating your account, please contact your team administrator.');
      } finally {
        this.isLoading = false;
      }
    },

    handleSubmit() {
      if (this.claimAccount) {
        return this.completeClaim();
      }
      return this.completeConfirmation();
    },

    async completeConfirmation() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('completeAccountConfirmation', {
          payload: {
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
          },
          token: this.token,
        });

        this.$store.dispatch('successToast', 'You have set up your account!');
        this.$store.dispatch('setActiveTeam', this.activeTeamId);
        if (this.$route.query && this.$route.query.shortcode) {
          await this.$store.dispatch('completeShortcode', {
            email: this.loggedInUser.email,
            shortcode: this.$route.query.shortcode,
            router: this.$router,
          });
        } else {
          setTimeout(() => this.$router.push({ name: 'root' }), 1000);
        }
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
      }
    },

    async completeClaim() {
      this.isLoading = true;
      try {
        await this.$store.dispatch('completeAccountClaim', {
          payload: {
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
          },
          token: this.token,
        });

        this.$store.dispatch('successToast', 'You have claimed your account!');
        this.$store.dispatch('setActiveTeam', this.activeTeamId);
        this.$router.push({ name: 'flights-list' });
      } catch (err) {
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
