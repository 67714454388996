<template>
  <div class="row d-flex align-items-center justify-content-center flex-column h-100">
    <div class="col-4">
      <div class="row">
        <h1 class="col text-center">Reset your password</h1>
      </div>
      <div class="row">
        <PasswordForm :token="token" />
      </div>
    </div>
  </div>
</template>

<script>
import PasswordForm from '@/components/Password/PasswordForm.vue';

export default {
  name: 'SetPasswordView',

  components: { PasswordForm },

  props: {
    token: { type: String, required: true },
  },
};
</script>
