<template>
  <div class="row d-flex align-items-center justify-content-center flex-column h-100">
    <div class="col-6 justify-content-center" v-if="accountConfirmationRequired">
      <h1 class="mt-5">
        Account confirmation sent, please check your email and confirm your account!
      </h1>
      <center>
        <Button
          data-testid="backButton"
          class="tertiary outline md"
          @click.native="() => {accountConfirmationRequired = false}">
          Back
        </Button>
        <Button
          data-testid="backButton"
          class="primary md"
          @click.native="handleSubmit">
          resend
        </Button>
      </center>
    </div>

    <form class="col-4" @submit.prevent="handleSubmit" v-else>
      <div class="row">
        <h1 class="col my-2 text-center">
          Enter your email to participate in the flight.
        </h1>
      </div>
      <div v-if="error" class="row">
        <div class="col">
          <span class="floating-label red">{{error}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col input-group">
          <b-input type="text"
            v-focus
            v-model.trim="email"
            :state="emailState"
            data-testid="email"
            id="email"
            name="email"
            ref="email"
            aria-label="Email"
            placeholder="Email"/>
        </div>
      </div>

      <Tip :show-title="false" color="white">Your email is only used to confirm and authenticate
      your account. Your identity will not be revealed as the author of any submitted content, and
      we never share your email address outside of Balloon.</Tip>

      <div class="row">
        <div class="col mt-5">
          <Button
            data-testid="submitButton"
            type="submit"
            class="primary lg"
            :is-loading="isLoading"
            :disabled="disableSubmitState">
            Submit
          </Button>
          <BackToLoginLink />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ShortcodeView',

  data() {
    return {
      error: '',
      email: '',
      accountConfirmationRequired: false,
      isLoading: false,
      loggedOut: false,
    };
  },

  computed: {
    ...mapGetters([
      'validateEmail',
      'shortcodeData',
      'loggedInUser',
    ]),

    emailState() {
      return this.email.length > 0 ? this.validateEmail(this.email) : null;
    },
    disableSubmitState() {
      return !this.emailState;
    },
  },

  watch: {
    $route() {
      if (this.$refs.email) {
        this.$nextTick(() => this.$refs.email.focus && this.$refs.email.focus());
      }
    },
  },

  created() {
    if (this.loggedInUser && this.loggedInUser.email) {
      this.logout();
    }
  },

  methods: {
    ...mapActions(['validateShortcode']),

    logout() {
      this.loggedOut = true;
      this.$store.dispatch('logout');
    },

    async onSuccess() {
      this.isLoading = true;
      try {
        const flightId = await this.$store.dispatch('completeShortcode', {
          email: this.shortcodeData.email,
          shortcode: this.shortcodeData.shortcode,
          router: this.$router,
        });

        this.$router.push({ name: 'flight-landing', params: { id: flightId } });
      } catch (err) {
        this.error = err.message;
        this.$store.dispatch('errorToast', 'There was a problem completing the shortcode.');
      } finally {
        this.isLoading = false;
      }
    },

    async handleSubmit() {
      try {
        this.isLoading = true;

        await this.validateShortcode({
          email: this.email,
          shortcode: this.$route.params.shortcode,
        });

        if (this.shortcodeData.validateCode) {
          this.$router.push({
            name: 'confirm-code',
            params: {
              shortcode: this.$route.params.shortcode,
              email: this.shortcodeData.email,
              onSuccess: this.onSuccess,
            },
          });

          return;
        }

        if (this.shortcodeData.loginRequired) {
          let loginRouteName = 'manualLogin';
          let loginRouteParams = { onSuccess: this.onSuccess };
          const query = { email: this.email, loginDomain: this.shortcodeData.loginDomain };
          if (this.shortcodeData.authMethod === 'saml') {
            loginRouteName = 'samlLogin';
            loginRouteParams = {};
            query.flightId = this.shortcodeData.flightId;
          }
          this.$router.push({
            name: loginRouteName,
            params: loginRouteParams,
            query,
          });
        }

        if (this.shortcodeData.accountConfirmationRequired) {
          this.accountConfirmationRequired = true;
        }
      } catch (err) {
        this.error = err.message;
        this.$store.dispatch('errorToast', 'There was a problem validating the shortcode.');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
p {
  font-size: 1.8rem;
  line-height: 1.56;
  color: @medium-tan;

  b {
    font-size: 1.8rem;
  }
}

form {
  border-radius: 4px;
  padding: 30px 30px;
  flex-basis: auto;

  button {
    text-transform: capitalize;
  }
}
</style>
