<template>
  <LoadingBalloonAnimation text="Logging out..."/>
</template>

<script>
export default {
  name: 'LogoutView',

  mounted() {
    this.$nextTick(() => {
      this.$store.dispatch('logout');
      // make 'logout' last minimum duration
      setTimeout(() => {
        this.$router.push('/login');
        this.$store.dispatch('infoToast', 'You have been logged out.');
      }, 1500);
    });
  },
};
</script>
