<template>
  <div class="row d-flex align-items-center justify-content-center flex-column h-100">
    <div class="col-6">

      <h1 class="text-center">
        A verification code has been sent to {{ email }}
      </h1>
      <h4 class="text-center medium-tan" >Please enter your 6-digit verification code here.</h4>

      <div class="d-flex justify-content-center mt-7">
        <!-- must leave first input enabled so mobile safari can trigger keyboard to show -->
        <input
          type="number"
          data-index="0"
          ref="code0"
          :class="{active: !code.length }"
          :value="code[0]"
          @paste.prevent
          pattern="[0-9]*"
          aria-label="Code digit 1"/>
        <input
          type="number"
          data-index="1"
          ref="code1"
          :class="{active: code.length === 1 }"
          :value="code[1]"
          @paste.prevent
          disabled
          pattern="[0-9]*"
          aria-label="Code digit 2"/>
        <input
          type="number"
          data-index="2"
          ref="code2"
          :class="{active: code.length === 2 }"
          :value="code[2]"
          @paste.prevent
          disabled
          pattern="[0-9]*"
          aria-label="Code digit 3"/>
        <input
          type="number"
          data-index="3"
          ref="code3"
          :class="{active: code.length === 3 }"
          :value="code[3]"
          @paste.prevent
          disabled
          pattern="[0-9]*"
          aria-label="Code digit 4"/>
        <input
          type="number"
          data-index="4"
          ref="code4"
          :class="{active: code.length === 4 }"
          :value="code[4]"
          @paste.prevent
          disabled
          pattern="[0-9]*"
          aria-label="Code digit 5"/>
        <input
          type="number"
          data-index="5"
          ref="code5"
          :class="{active: code.length === 5 || code.length === 6 }"
          :value="code[5]"
          @paste.prevent
          disabled
          pattern="[0-9]*"
          aria-label="Code digit 6"/>
      </div>

      <div class="row mt-5" v-if="isLoading">
        <div class="col">
          <Spinner class="ml-auto mr-auto" />
        </div>
      </div>

      <div class="row mt-7">
        <div class="col text-center">
          <Button :is-loading="isResending" @click.native.prevent="resendCode" class="tertiary">
            Resend Code
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Spinner from '@/components/Spinner.vue';

const isNumber = /^[0-9]$/i;

export default {
  name: 'ConfirmCodeView',

  components: { Spinner },

  data() {
    return {
      code: [],
      email: this.$route.params.email,
      isLoading: false,
      isResending: false,
    };
  },

  watch: {
    code() {
      if (this.isCodeFormatValid()) {
        this.triggerCodeValidation();
      }
    },
  },

  created() {
    if (!this.email) {
      this.$store.dispatch('errorToast', 'There was a problem submitting your confirmation. Please try again!');
      const route = this.$route.params.shortcode
        ? { name: 'shortcode', params: { shortcode: this.$route.params.shortcode } }
        : { name: 'login' };
      this.$router.push(route);
    }
  },

  beforeDestroy() {
    window.removeEventListener('paste', this.manualPasteCode);
    window.removeEventListener('keydown', this.handleCode);
  },

  mounted() {
    window.addEventListener('paste', this.manualPasteCode);
    window.addEventListener('keydown', this.handleCode);
  },

  methods: {
    ...mapActions(['validateCode', 'validateShortcode']),

    isCodeFormatValid() {
      if (this.code.length < 6) { return false; }

      let isValid = true;

      for (let i = 0; i < this.code.length; i += 1) {
        if (!this.code[i]) {
          isValid = false;
        }
      }

      return isValid;
    },

    handleCode(e) {
      if (this.isLoading) {
        e.preventDefault();
        return;
      }

      if (e.key.toLowerCase() === 'backspace' && this.code.length) {
        this.code.pop();
        e.preventDefault();
        return;
      }

      if (isNumber.test(e.key) && !this.code.length < 6) {
        this.code.push(e.key);
        e.preventDefault();
      }
    },

    setCode(code) {
      const isCodeValid = code && code.length === 6 && !Number.isNaN(parseInt(code, 10)) && this.code.join('') !== code;
      if (isCodeValid) {
        this.code = [...code];
      }
    },

    manualPasteCode(e) {
      const text = (e.clipboardData || window.clipboardData).getData('text');
      this.setCode(text);
      if (!this.code.length) {
        this.$store.dispatch('errorToast', 'The code you are trying to paste does not have a valid format.');
      }
      e.preventDefault();
    },

    restrictUpdate(e) {
      if (e.key.toLowerCase() === 'backspace') {
        return;
      }

      if (e.target.value) {
        e.preventDefault();
      }
    },

    async resendCode() {
      this.isResending = true;
      try {
        await this.validateShortcode({
          email: this.email,
          shortcode: this.$route.params.shortcode,
          resend: true,
        });
      } catch (err) {
        this.$store.dispatch('errorToast', 'There was a problem resending the verification code, please try again.');
      } finally {
        this.isResending = false;
      }
    },

    async triggerCodeValidation() {
      this.isLoading = true;

      setTimeout(async () => {
        try {
          await this.$store.dispatch('validateCode', {
            code: this.code.join(''),
            email: this.email,
          });

          await this.$route.params.onSuccess();
        } catch (err) {
          this.error = err.message;
          this.$store.dispatch('errorToast', 'Your verification code is invalid, please double check it or click resend');
        } finally {
          this.isLoading = false;
        }
      }, 1000);
    },
  },
};
</script>

<style lang="less" scoped>

input[type='number'] {
  font-size: 6rem;
  width: 50px;
  height: 75px;
  background: transparent;
  color: @teal;
  letter-spacing: 0.5px;
  line-height: 1;
  font-weight: normal;
  border-bottom: 2px solid @medium-navy;
  text-align: center;
  padding: 1rem 0 1.2rem 0;
  margin-left: 1.8rem;

  caret-color: transparent;

  &.active {
    border-bottom: 3px solid @teal;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
