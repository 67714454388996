<template>
  <div class="row d-flex align-items-center justify-content-center flex-column h-100">
    <form class="col-4" @submit.prevent="handleSubmit">
      <div class="row">
        <h1 class="col my-2 text-center">
          {{ getHeaderText() }}
        </h1>
      </div>

      <div v-if="!isLoginDomainSet" class="row" :class="{'invalid': domainError }">
        <div class="col-12 input-group">
          <b-input type="text"
            v-model.trim="loginDomain"
            :state="loginDomainState"
            data-testid="loginDomain"
            id="loginDomain"
            name="loginDomain"
            aria-label="loginDomain"
            placeholder="team-login-domain"/>
        </div>
        <Error class="medium-tan">
          <span>{{ domainError }}</span>
        </Error>
      </div>

      <div v-if="isLoginDomainSet" class="row" :class="{'invalid': error }">
        <div class="col-12 input-group">
          <b-input type="text"
            v-model.trim="email"
            :state="emailState"
            v-focus
            data-testid="email"
            id="email"
            name="email"
            ref="email"
            aria-label="Email"
            placeholder="Email"
          />
        </div>
        <Error class="medium-tan" >
          <span>{{ error }}</span>
        </Error>
      </div>

      <div v-if="isLoginDomainSet" class="row">
        <div class="col input-group">
          <b-input type="password"
            v-model="password"
            :state="passwordState"
            data-testid="password"
            id="password"
            name="password"
            aria-label="Password"
            placeholder="Password"/>
        </div>
      </div>

      <div class="row">
        <div class="col mt-5">
          <Button
            data-testid="loginButton"
            type="submit"
            class="primary lg"
            :is-loading="isLoading"
            :disabled="disableSubmitState">
            {{ getSubmitButtonText() }}
          </Button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <BackToLoginLink
            :show-email="true"
            :show-manual="false"
            :login-domain-set="isLoginDomainSet" />
          <router-link to="/signup">
            Not a Balloon user? Sign up
          </router-link>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PasswordLoginView',

  data() {
    return {
      domainError: '',
      error: '',
      email: this.$route.query.email || '',
      password: '',
      loginDomain: this.$route.query.loginDomain || '',
      isLoading: false,
      isLoginDomainSet: false,
    };
  },

  computed: {
    ...mapGetters([
      'validateEmail',
    ]),
    emailState() {
      return this.email.length > 0 ? this.validateEmail(this.email) : null;
    },
    loginDomainState() {
      return this.loginDomain.length === 0 ? null : true;
    },
    passwordState() {
      return this.password.length === 0 ? null : true;
    },
    disableSubmitState() {
      return this.loginDomainState
        ? !this.loginDomainState
        : (!this.emailState || !this.passwordState);
    },
  },

  watch: {
    $route() {
      this.$nextTick(() => this.$refs.email.focus && this.$refs.email.focus());
    },
  },

  methods: {
    getHeaderText() {
      return 'Sign in to your team';
    },
    getSubmitButtonText() {
      if (this.isLoginDomainSet) {
        return 'Sign in';
      }
      return 'Continue';
    },
    handleSubmit() {
      if (this.isLoginDomainSet) {
        return this.login();
      }
      return this.checkDomain();
    },
    async login() {
      try {
        this.isLoading = true;
        this.error = '';
        await this.$store.dispatch('login', {
          teamDomain: this.loginDomain,
          email: this.email,
          password: this.password,
        });
        if (this.$route.params.onSuccess) {
          await this.$route.params.onSuccess();
        } else {
          const route = this.$route.query && this.$route.query.redirectUrl
            ? this.$route.query.redirectUrl
            : { name: 'flight-list' };
          this.$router.push(route);
        }
      } catch (err) {
        this.error = 'There was a problem logging in. You might want to try the password-free sign in.';
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
      }
    },
    async checkDomain() {
      try {
        this.isLoading = true;
        this.domainError = '';
        this.isLoginDomainSet = await this.$store.dispatch('checkTeamDomain', this.loginDomain);
      } catch (err) {
        this.domainError = 'There was a problem verifying your login domain. You might want to try the password-free sign in.';
        this.$store.dispatch('errorToast', err.message);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
form {
  border-radius: 4px;
  padding: 30px 30px;
  flex-basis: auto;

  button {
    text-transform: capitalize;
  }
}

a {
  color: @medium-tan;

  &:hover { text-decoration: underline }
}

span a { font-size: 1rem;}
</style>
